import React from "react"
// import { graphql } from "gatsby"

// import EventList from "../components/eventList"
import Layout from "../components/layout"
import Snippet from "../components/snippet"

export default ({ data }) => (
	// <EventList
	// 	type="Trainings"
	// 	meta={{
	// 		title: "Trainings",
	// 		description:
	// 			"Check out the awesome Java trainings at Accento on September 29th 2021!",
	// 		keywords: `Java trainings Karlsruhe`,
	// 		path: "/trainings",
	// 	}}
	// 	lists={[
	// 		{
	// 			snippetId: "trainings-intro",
	// 			events: data.trainings.nodes,
	// 			showByline: true,
	// 		}
	// 	]}
	// />
	<Layout
		meta={{
			title: "Trainings",
			description: "Unfortunately, no online trainings at Accento in 2021!",
			keywords: `Java trainings Karlsruhe`,
			path: "/trainings",
		}}
		wide
	>
		<Snippet id="trainings-canceled" />
	</Layout>
)

// export const query = graphql`
// 	query {
// 		trainings: allMarkdownRemark(
// 			sort: { fields: frontmatter___priority, order: ASC }
// 			filter: { fields: { collection: { eq: "trainings" } } }
// 		) {
// 			...AllEventNodes
// 		}
// 	}
// `
